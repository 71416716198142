import React, { useEffect, useRef, useState } from 'react';
import './horizontalTimeline.scss';

const HorizontalTimeline = ({histories}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidingX, setSlidingX] = useState(0);
  const [isSlidingEnd, setSlidingEnd] = useState(false);
  const viewportRef = useRef(null);
  const slidesWrapRef = useRef(null);
  const contentSectionRef = useRef(null);

  const slideTo = (dir) => {
    if(!viewportRef.current || !slidesWrapRef.current) return;
    const viewportWidth = viewportRef.current.getBoundingClientRect().width;
    const slideWrapsWidth = slidesWrapRef.current.getBoundingClientRect().width;
    const dirMultiplier = dir === 'next' ? -1 : +1;
    
    let newSlidingEnd = false;
    let newSlidingX = slidingX + (viewportWidth * dirMultiplier);

    if(slidingX === 0 && dir === 'prev') {
      return;
    } else if (slideWrapsWidth < (Math.abs(newSlidingX) + viewportWidth)) {
      newSlidingX = slidingX - (slideWrapsWidth - Math.abs(slidingX)) + viewportWidth;
      newSlidingEnd = true;
    } else if(0 < newSlidingX) {
      newSlidingX = 0;
    }

    setSlidingX(newSlidingX);
    setSlidingEnd(newSlidingEnd);
  };

  const onResize = () => {
    if(!slidesWrapRef.current) return;
    const buttonYear = slidesWrapRef.current.querySelectorAll('.button-year');
    const {left: buttonLeft, right: buttonRight} = buttonYear[activeIndex].getBoundingClientRect();
    const {right: viewportRight, left: viewportLeft, width: viewportWidth} = viewportRef.current.getBoundingClientRect();

    if(buttonRight > viewportRight || buttonLeft < viewportLeft) {
      setSlidingX(-(buttonLeft - (viewportWidth / 2)));
    }
  };

  useEffect(() => {
    if(!contentSectionRef.current) return;
    console.log('contentSectionRef.current', contentSectionRef.current);
    contentSectionRef.current.querySelector('a').focus();
  }, [activeIndex]);

  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [activeIndex]);

  return (
    <div className='time-line'>
      <div className='time-line-tabs'>
        <div ref={viewportRef} className='viewport'>
          <ol ref={slidesWrapRef} className='wrap-slides' style={{
            transform: `translateX(${slidingX}px)`
          }} data-active={activeIndex}>
            {histories.map(({publicYear},i) => (
              <li key={publicYear} className={`slide ${activeIndex===i ? 'active':''}`}>
                <button className="button-year" onClick={() => setActiveIndex(i)}>
                  <span className='year'>{publicYear}</span><span className="blind">년 추진경과 자세히 보기</span>
                  <span className='dot'></span>
                </button>
              </li>
            ))}
          </ol>
        </div>
        <div className="wrap-arrow">
          <button className="arrow prev" onClick={() => slideTo('prev')} disabled={slidingX === 0}  aria-hidden tabIndex={-1}>
            <span className="blind">이전</span>
          </button>
          <button className="arrow next" onClick={() => slideTo('next')} disabled={isSlidingEnd}  aria-hidden tabIndex={-1}>
            <span className="blind">다음</span>
          </button>
        </div>
      </div>
      {histories.length > 0  && (
       <section ref={contentSectionRef} className='time-line-content'>
         <h2 className="blind">{histories[activeIndex].publicYear}년 추진경과</h2>
         <ul className="content-list">
            {histories[activeIndex].contentList.map((content) => (
            <li key={content.id}>
              <a href={getURLsFromString(content.content)} target="_blank" rel="noopener noreferrer" title="새창">{content.title}</a>
            </li>
            ))}
         </ul>
       </section>
      )}
    </div>
  );
};
  
function getURLsFromString(str) {
  var re = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%\/.\w-]*)?\??(?:[-+=&;%@.\w]*)#?\w*)?)/gm; 
  var m;
  var arr = [];
  while ((m = re.exec(str)) !== null) {
    if (m.index === re.lastIndex) {
        re.lastIndex++;
    }
    arr.push(m[0]);
  }
  return arr;
}

export default HorizontalTimeline;