// URL Config PRODUCTION
// export const API_SERVER_FILE = 'http://10.16.121.177:8088/';
export const API_SERVER_FILE = `${window.location.origin}/`;
// export const API_SERVER_FILE = "http://localhost:8080/";
//export const API_SERVER_FILE = 'http://192.168.101.16:8081/';
export const API_SERVER_URL = `${API_SERVER_FILE}audit-api/audit/`;
export const API_SERVER_UPLOAD = `${API_SERVER_FILE}audit-api/common/`;
export const HYUNDAI_SITE_ID = "/hyundai/site1";
export const API_R_401 = 401;
export const API_R_500 = 500;
export const GBSize = 1073741824;
export const MBSize = 1048576;
