import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { savePDF } from '@progress/kendo-react-pdf';
import ButtonLink from '../../../components/ButtonLink';
import CodeOfConductDefault from '../../../components/DefaultContent/EthicsStandard/CodeOfConductDefault';
import {
  apiListPublicDateEthics,
  apiContentEthicsById,
  downloadFileUploadDettailContentAPI,
  downloadFileUploadDetailContent
} from '../../../services/api/index';
import './style.scss';
import Dropdown from '../../../components/Dropdown';

class CodeOfConduct extends Component {
  state = {
    listPublicDate: [],
    listContentEthics: null,
    selectBoxVal: null,
    firstIdRecord: null,
    pdfDisplay: 'none',
    btnActive: null,
    selectBoxActive: null
    // loading: false
  };

  componentDidMount() {
    var activeTabDom = document.getElementsByClassName('ethics_children');
    if (this.props.tabClick != '2') {
      activeTabDom[1].focus();
      activeTabDom[1].click();
      setTimeout(() => {
        document.getElementsByClassName('et_link')[1].focus();
      }, 200);
    }
    document.getElementsByClassName('et_link')[0].removeAttribute('title');
    document
      .getElementsByClassName('et_link')[1]
      .setAttribute(
        'title',
        this.props.activeLanguage.code === 'en' ? 'Selected' : '선택됨'
      );
    activeTabDom[1].className = 'ethics_children ethics_active';
    activeTabDom[0].className = 'ethics_children ethics_inactive';
    this.props.func('0');
    this.loadPublicDate(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.data !== nextProps.data ||
      this.props.activeLanguage.code !== nextProps.activeLanguage.code
    ) {
      // this.setState({
      //   listContentEthics: nextProps.data[0]
      // });
      this.loadPublicDate(nextProps);
    }
  }

  loadPublicDate = async props => {
    if (props.data.length > 0) {
      let contentId = props.data[0].id;
      let result = await apiListPublicDateEthics(
        props.activeLanguage.code,
        14,
        contentId
      );

      if (result.data.data) {
        this.setState({
          listPublicDate: this.convertPublicDate(
            result.data.data.publicDateList
          ),
          // loading: true,
          selectBoxVal: this.convertPublicDate(
            result.data.data.publicDateList
          )[0]
            ? this.convertPublicDate(result.data.data.publicDateList)[0].id
            : 0,
          firstIdRecord: this.convertPublicDate(
            result.data.data.publicDateList
          )[0]
            ? result.data.data.publicDateList[0].id
            : 0,
          listContentEthics: this.props.data ? this.props.data[0] : null
        });
        let id = result.data.data.publicDateList[0]
          ? result.data.data.publicDateList[0].id
          : 0;
        let publicDate = result.data.data.publicDateList[0]
          ? result.data.data.publicDateList[0].publicDate
          : '';

        // if (props.activeLanguage.code === "en") {
        //   let arrDate = [];
        //   arrDate = publicDate.split("-");
        //   publicDate = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
        // }
        await apiContentEthicsById(
          14,
          publicDate,
          props.activeLanguage.code
        ).then(res => {
          this.setState({
            listContentEthics: res.data.data
            // loading: false
          });
        });
      }
    }
  };
  loadContent = key => {
    let id;
    let publicDate = key;
    this.state.listPublicDate.map(el => {
      if (el.publicDate == key) {
        id = el.key;
        return true;
      }
    });
    // this.setState({
    //   loading: true,
    // })
    apiContentEthicsById(14, publicDate, this.props.activeLanguage.code).then(
      res => {
        this.setState({
          listContentEthics: res.data.data
          // loading: false
        });
      }
    );
    // }
  };
  onSelectedValue = key => {
    this.setState(
      {
        selectBoxVal: key
      },
      this.loadContent(key)
    );
    setTimeout(() => {
      const contentWrap = document.querySelector('.coe_post3_wrap');
      const firstContent = contentWrap.querySelector('h2,p');
      if(firstContent) {
        firstContent.setAttribute('tabindex', 0);
        firstContent.focus();
      }
    }, 100);
  };

  convertPublicDate = data => {
    let newData = [];
    data.map(el => {
      let strDate = el.publicDate;
      if (this.props.activeLanguage.code === 'en') {
        let mMoment = moment(el.publicDate, 'DD-MM-YYYY');
        strDate = mMoment.format('DD-MMM-YYYY');
      }
      let newEl = {};
      newEl.key = el.publicDate;
      newEl.id = el.publicDate;
      newEl.publicDate = strDate;
      newData.push(newEl);
    });
    return newData;
  };

  createPdf = html => {
    savePDF(html, {
      paperSize: 'Letter',
      fileName: 'EthicsStandard.pdf',
      margin: {
        bottom: 20,
        left: 20,
        right: 20,
        top: 20
      }
    });
  };
  pxToMm = px => {
    return Math.floor(px / document.getElementById('myMm').offsetHeight);
  };

  mmToPx = mm => {
    return document.getElementById('myMm').offsetHeight * mm;
  };

  range = (start, end) => {
    return Array(end - start)
      .join(0)
      .split(0)
      .map(function(val, id) {
        return id + start;
      });
  };
  convertPDF = id => {
    this.setState({
      pdfDisplay: 'block'
    });
    let input = document.getElementById(id);
    let inputHeightMm = this.pxToMm(input.offsetHeight);
    let a4WidthMm = 210;
    let a4HeightMm = 297;
    let a4HeightPx = this.mmToPx(a4HeightMm);
    let numPages =
      inputHeightMm <= a4HeightMm
        ? 1
        : Math.floor(inputHeightMm / a4HeightMm) + 1;
    // console.log({
    //   input, inputHeightMm, a4HeightMm, a4HeightPx, numPages, range: this.range(0, numPages),
    //   comp: inputHeightMm <= a4HeightMm, inputHeightPx: input.offsetHeight
    // });

    html2canvas(input).then(canvas => {
      let imgData = canvas.toDataURL('image/png');
      let pdf;
      // Document of a4WidthMm wide and inputHeightMm high
      if (inputHeightMm > a4HeightMm) {
        // elongated a4 (system print dialog will handle page breaks)
        pdf = new jsPDF('p', 'mm', [inputHeightMm + 16, a4WidthMm]);
      } else {
        // standard a4
        pdf = new jsPDF();
      }

      pdf.addImage(imgData, 'PNG', 0, 0, a4WidthMm, inputHeightMm);
      pdf.save(`${id}.pdf`);
    });
    this.setState({
      pdfDisplay: 'none'
    });
  };
  onDownloadPDF = async () => {
    // return window.open(href, "_blank");
    const { listContentEthics } = this.state;
    let params = {};
    params.id = listContentEthics.id;
    params.fileName = listContentEthics.fileName;
    let result = await downloadFileUploadDettailContentAPI(params);
    if (result) {
      return (window.location.href = `${downloadFileUploadDetailContent(
        params
      )}`);
    }
  };
  render() {
    const { translate } = this.props;
    let {
      listContentEthics,
      selectBoxVal,
      listPublicDate,
      pdfDisplay,
      btnActive,
      selectBoxActive
      // loading
    } = this.state;

    // // let bodyRef = React.createRef();
    if (listContentEthics) {
      return (
        <div>
          {/* {loading ? <Loading /> : null} */}
          {/* <div
            className="print_pdf"
            id="EthicsStandard"
            style={{ display: pdfDisplay }}
          >
            <div className="ce_content_wrap">
              <div className="coe_content_detail">
                <div className="coe_post3">
                  <div className="EthicsStandard">
                    <div
                      className="post_detail_3"
                      style={{ borderBottom: '2px solid red' }}
                    >
                      <div className="pd_content">
                        <div className="item_left">
                          <h1 className="left_title">
                            <span>{listContentEthics.title}</span>
                          </h1>
                          <div className="left_content_1">
                            <span> {listContentEthics.describe}</span>
                          </div>
                        </div>
                        <div className="item_right"></div>
                      </div>
                    </div>
                    <div
                      className="post_content_3"
                      tabIndex='0'
                      dangerouslySetInnerHTML={{
                        __html: listContentEthics.content
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="CodeOf_Conduct">
            <div className="ce_content_wrap">
              <div className="coe_content_detail">
                <div className="right_date">
                  <Dropdown data={listPublicDate.map(({id,publicDate}) => ({id, value:publicDate}))} onClick={this.onSelectedValue} />
                  <ButtonLink
                    href='javascript: void(0)'
                    text="PDF 다운로드"
                    className={`button-download ${btnActive}`}
                    onKeyPress={(e) => {
                      if(e.key === 'Enter'){
                        this.onDownloadPDF();
                        this.setState({ btnActive: 'btn-active' });
                      }
                    }}
                    onClick={() => {
                      this.onDownloadPDF();
                      this.setState({ btnActive: 'btn-active' });
                    }}
                  />
                </div>
                <div
                  className="coe_post3_wrap"
                  dangerouslySetInnerHTML={{
                    __html: listContentEthics.content
                  }}
                >
                  {/* <div className="item_right_mobile">
                    <img src="/Images/A3.svg" alt="윤리헌장" title="윤리헌장" />
                  </div>

                  <div className="EthicsStandard">
                    <div className="code_of_ethics_wrap">
                      <div className="post_detail_3">
                        <div className="pd_content">
                          <h1 className="left_title">
                            <span>{listContentEthics.title}</span>
                          </h1>
                          <div className="pdi_wrap">
                            <div className="item_left">
                              <div className="left_content_1">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: listContentEthics.describe
                                    // .split('\n')
                                    // .join('<br/>')
                                  }}
                                ></span>
                              </div>
                            </div>
                            <div className="item_right">
                              <img
                                className="i_image"
                                src="/Images/A3.svg"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="post_content_3"
                        tabIndex="0"
                        dangerouslySetInnerHTML={{
                          __html: listContentEthics.content
                        }}
                      ></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <CodeOfConductDefault lang={this.props.activeLanguage.code} />;
    }
  }
}
export default withLocalize(CodeOfConduct);
 