import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { Helmet } from 'react-helmet';
import moment from 'moment';
import * as yup from 'yup';
import styled from 'styled-components';
import { Radio, Modal } from 'antd';
// import ModalReportInfo from './modalReportInfo';
import Loading from '../../../components/Loading';
import Upload from '../../../components/Input/Upload';
import DatePicker from '../../../components/DatePickerInput';
import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';
import BannerContent from '../../../components/BannerContent';
// import { showToast, showToastError } from '../../../components/Modal';
import BreadCrumb from '../../../components/BreadCrumb';
import { MESSAGE } from '../../../constants/error';
import { GBSize, MBSize, HYUNDAI_SITE_ID } from '../../../constants';
import {
  ConfirmReport,
  SearchReport,
  downloadFileUpload,
  ReplyReport,
  commonDeleteFile
} from '../../../services/api';

import './style.scss';
import { focusReturnModalClose } from '../../../utils/webAccess';
const Flex = styled.div`
  display: flex;
`;

const ReportDetail = ({ activeLanguage, history, translate }) => {
  const DATE_FORMAT_EN = 'DD-MMM-YYYY';
  const DATE_FORMAT_KR = 'YYYY-MM-DD';
  const { code } = activeLanguage;
  let schema = {
    content: yup.string().required(MESSAGE.MSG7),
    listFiles: yup.array()
  };
  const { location } = history;
  const { pathname } = location;

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.getElementById('backtotopreportdetail').blur();
  };

  const handleKeyPress=(event) =>{
    if(event.key === 'Enter'){      
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // let [reportDetail, setReportDetail] = useState(null);
  // let [satisfaction, setSatisfaction] = useState(null);
  let [visible, setVisible] = useState(false);
  let [modalStart, setModalStart] = useState(false);
  let [title, setTitle] = useState('');
  let [dataPopup, setDataPopup] = useState(null);
  let [reportDate, setReportDate] = useState('');
  let [statusCode, setStatusCode] = useState('');
  // let [fileNames, setFileNames] = useState('');
  // let [replycomment, setReplycomment] = useState('');
  // let [certificate, setCertificate] = useState('');
  let [loading, setLoading] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const [isValidFile, setIsValidFile] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const [btnActive, setBtnActive] = useState(null);
  // let [statusCodeReportAction, setStatusCodeReportAction] = useState(null);
  let [replyList, setReplyList] = useState([]);
  let [isSubmit, setIsSubmit] = useState(false);
  let [isRemoveItems, setIsRemoveItems] = useState(false);
  let [birthday, setBirthday] = useState(null);
  let [nameCustomer, setNameCustomer] = useState();
  const report_code = sessionStorage.getItem('code');
  const report_lang = activeLanguage.code;

  const reformattedReportDate = activeLanguage.code === 'kr' ? moment(reportDate).format(DATE_FORMAT_KR) : moment(reportDate).format(DATE_FORMAT_EN);
  const { control, errors, handleSubmit, register, setValue, reset } = useForm({
    validateCriteriaMode: 'all',
    mode: 'onChange',
    validationSchema: yup.object().shape(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      listFiles: []
    }
  });
  useEffect(() => {
    
    let params = {};
    params.certificate = report_code;
    params.lang = report_lang;
    params.searchAction = 'SEARCH_ACTION';
    SearchReport(params)
      .then(response => {
        let data = response.data.data;
        setDataPopup(data);
        // setStatusCodeReportAction(data.statusCodeReportAction);
        setTitle(data.title);
        setReportDate(data.reportDate);
        setStatusCode(data.statusCode);
        // setFileNames(data.fileNames);
        // setReplycomment(data.replycomment);
        // setCertificate(data.certificate);
        setReplyList(data.replyList);
        setBirthday(report_lang === 'kr' ? moment(data.birthday).format(DATE_FORMAT_KR) : moment(data.birthday).format('DD-MM-YYYY'));
      })

      .catch(e => { });

  }, [isSubmit]);

  const onSubmit = ({ titleReport, content, listFiles }) => {
    setLoading(true);
    setIsSubmit(true);
    setVisible(false);
    ReplyReport({
      title: titleReport,
      content: content,
      certificate: sessionStorage.getItem('code'),
      listFiles: listFiles.map(item => {
        return {
          originalFileName: item.name,
          storedFileName: item.response.data[0].storedFileName,
          fileSize: item.size
        };
      })
    })
      .then(response => {
        reset();
        setIsRemoveItems(true);
        setLoading(false);
        setIsSubmit(false);
        // const { data } = response;
        // sessionStorage.setItem('code', data.data);
        // history.push('/report/certificate');
        setIsRemoveItems(false);
      })
      .catch(err => {
        setIsSubmit(false);
        setLoading(false);
      });
  };
  const onUploadDone = e => {
    if (e.size > 0) {
      const downloadFileName = document.getElementsByClassName('anticon-download');
      var i;
      for (i= 0; i <downloadFileName.length ; i++){
        downloadFileName[i].setAttribute('title', translate('createReport.download'));
      }
      const removeFileName = document.getElementsByClassName('anticon-delete');
      var y;
      for (y=0; y<removeFileName.length ; y++){
        removeFileName[y].setAttribute('title', translate('createReport.remove'));
      }
      setTotalFileSize(totalFileSize + e.size);
      if ((totalFileSize + e.size) / GBSize > 1) {
        setIsValidFile(false);
        setErrorMsg(translate('createReport.errorUpload'));
      }
    }
  };
  const onRemove = e => {
    if (e.size > 0) {
      if (e.size / MBSize > 50) {
        setIsValidFile(true);
        setErrorMsg('');
      } else {
        setTotalFileSize(totalFileSize - e.size);
        if ((totalFileSize - e.size) / GBSize < 1) {
          setIsValidFile(true);
          setErrorMsg('');
        }
      }
    }
    const data = {
      originalFileName: e.originFileObj ? e.originFileObj.name : e.name
    };
    commonDeleteFile(data)
      .then(res => { })
      .catch(e => { });
  };
  const onErrorFile = res => {
    setIsValidFile(false);
    setErrorMsg(translate('createReport.errorMessage'));
  };
  // change validation messsage by language
  if (errors.titleReport) {
    if (errors.titleReport.types.required) {
      errors.titleReport.message = translate('reportDetail.titleRequired');
    } else if (errors.titleReport.types.max) {
      errors.titleReport.message = translate('report.titleMax');
    }
  }
  if (errors.content) {
    if (errors.content.types.required) {
      errors.content.message = translate('reportDetail.contentRequired');
    }
  }

  // const onReplyReport = certificate => {
  //   ConfirmReport({
  //     certificate: certificate,
  //     url: HYUNDAI_SITE_ID
  //     // satisfaction
  //   })
  //     .then(response => {
  //       // history.push('/report/report-reply');
  //     })
  //     .catch(err => {
  //       showToastError(translate('createReport.errorMessage'), {
  //         autoClose: 5000
  //       });
  //     });
  // };
  // useEffect(() => {}, [loading]);
  return (
    <div className="ld_background">
      {loading ? <Loading /> : null}
      <Helmet>
        <title>{translate('helmet.reportdetail')}</title>
      </Helmet>
      <BannerContent
        title={translate('reportProcess.bannerTitle')}
        content={translate('reportDetail.bannerContent')}
        background="/Images/Groupbanner31171.png"
      />
      <div className="report_detail">
        <BreadCrumb
          firstUrl={translate('reportProcess.firsturl')}
          secondUrl={translate('reportProcess.breadCrumb2')}
          firstRouter="/report/guideline"
          secondRouter="/report/report-detail"
        />
        <div className="rd_wrap">
          <div className="rdw_content">
            <div className="rdw_border">
              <div className="rdwc_list_item">
                <div
                  className={`rdwcl_item ${
                    statusCode === '1' ? 'bgr_active' : null
                    }`}
                >
                  <div className="bgr_item">
                    <img
                      src={
                        statusCode === '1'
                          ? '/Images/step1active.svg'
                          : '/Images/icreportorigin.svg'
                      }
                      alt={statusCode === '1' ? translate('webAccessibility.step1ActiveAlt') : translate('webAccessibility.confirmStep1Alt')}
                      title={statusCode === '1' ? translate('webAccessibility.step1ActiveAlt') : translate('webAccessibility.confirmStep1Alt')}
                    />
                  </div>
                  <span>
                    <span>
                      {statusCode === '1'
                        ? translate('reportDetail.status1')
                        : translate('reportProcess.spanStep1')}
                    </span>
                  </span>
                </div>
                <div
                  className={`rdwcl_item ${
                    statusCode === '2' ? 'bgr_active' : null
                    }`}
                >
                  <div className="bgr_item">
                    <img
                      src={
                        statusCode === '2'
                          ? '/Images/ic-step2-active.svg'
                          : '/Images/icreportnext11.svg'
                      }
                      alt={statusCode === '2' ? translate('webAccessibility.step2ActiveAlt') : translate('webAccessibility.confirmStep2')}
                      title={statusCode === '2' ? translate('webAccessibility.step2ActiveAlt'): translate('webAccessibility.confirmStep2')}
                    />
                  </div>
                  <span>
                    <span>
                      {statusCode === '2'
                        ? translate('reportDetail.status2')
                        : translate('reportProcess.spanStep2')}
                    </span>
                  </span>
                </div>
                <div
                  className={`rdwcl_item 
                    ${
                    statusCode === '3' ||
                      statusCode === '4' ||
                      statusCode === '5' ||
                      statusCode === '6'
                      ? 'bgr_active'
                      : null
                    }`}
                >
                  <div className="bgr_item">
                    <img
                      src={
                        statusCode === '3' ||
                          statusCode === '4' ||
                          statusCode === '5' ||
                          statusCode === '6'
                          ? '/Images/step3active.svg'
                          : '/Images/icreportsearch11.svg'
                      }
                      alt={                        
                      statusCode === '3' ||
                      statusCode === '4' ||
                      statusCode === '5' ||
                      statusCode === '6' ? translate('webAccessibility.step3ActiveAlt') : translate('webAccessibility.confirmStep3')}
                      title={                      
                        statusCode === '3' ||
                        statusCode === '4' ||
                        statusCode === '5' ||
                        statusCode === '6' ? translate('webAccessibility.step3ActiveAlt'): translate('webAccessibility.confirmStep3')}
                    />
                  </div>
                  <span>
                    <span>
                      {/* {statusCode === '3'
                        ? translate('reportDetail.status3')
                        : null}
                      {statusCode === '4'
                        ? translate('reportDetail.status4')
                        : null}
                      {statusCode === '5'
                        ? translate('reportDetail.status5')
                        : null}
                      {statusCode === '6'
                        ? translate('reportDetail.status6')
                        : null} */}
                      {statusCode === '3' ||
                        statusCode === '4' ||
                        statusCode === '5' ||
                        statusCode === '6'
                        ? translate('reportDetail.status3')
                        : translate('reportProcess.spanStep3')}
                    </span>
                  </span>
                </div>
                <div
                  className={`rdwcl_item ${
                    statusCode === '7' ? 'bgr_active' : null
                    }`}
                >
                  <div className="bgr_item">
                    <img
                      src={
                        statusCode === '7'
                          ? '/Images/ic-step4-active.svg'
                          : '/Images/icreportinactive.svg'
                      }
                      alt={statusCode === '7' ? translate('webAccessibility.step4ActiveAlt') : translate('webAccessibility.confirmStep4')}
                      title={statusCode === '7' ? translate('webAccessibility.step4ActiveAlt') : translate('webAccessibility.confirmStep4')}
                    />
                  </div>
                  <span>
                    <span>
                      {statusCode === '7'
                        ? translate('reportDetail.status7')
                        : translate('reportProcess.spanStep4')}
                    </span>
                  </span>
                </div>
              </div>
            </div>

            <div className="rdwc_detail">
              <div className="detail_title">
                <span>
                  {statusCode === '1' ? translate('reportDetail.text1') : null}
                  {statusCode === '2' ? translate('reportDetail.text2') : null}
                  {statusCode === '3' ||
                    statusCode === '4' ||
                    statusCode === '5' ||
                    statusCode === '6'
                    ? translate('reportDetail.text3')
                    : null}
                  {statusCode === '7' ? translate('reportDetail.text4') : null}
                </span>
              </div>
              <div className="detail_form">
                <div className="df_width df_content df_background df_textWrap df_btop">
                  {translate('reportDetail.title')}
                </div>
                <div className="df_content df_textWrap df_btop df_bright">
                  <button
                    className="df_highlight"
                    id='df_highlight'
                    onClick={() => {
                      setModalStart(true);
                      focusReturnModalClose({id: 'df_highlight'});
                    }}
                  >
                    {title}
                  </button>
                  <Modal
                    className="ModalReportDetail"
                    visible={modalStart}
                    footer={null}
                    maskClosable
                    width="792px"
                    onCancel={e => {
                      e.stopPropagation();
                      setModalStart(false);
                    }}
                    closeIcon={<img src="/Images/icclose36px.svg" alt={translate('webAccessibility.closeButton')} />}
                  >
                    <Input
                      forLabel={translate('reportRegistration.subsidiary')}
                      classname_input="rf_input"
                      inputTitle={translate('reportRegistration.subsidiary')}
                      hidden
                      disable
                      name="subsidiary"
                      defaultValue={dataPopup ? dataPopup.subsidiaryName : null}
                    />
                    <Input
                      forLabel={translate('reportRegistration.title')}
                      classname_input="rf_input"
                      inputTitle={translate('reportRegistration.title')}
                      hidden
                      disable
                      name="titleReport"
                      defaultValue={dataPopup ? dataPopup.title : null}
                    />
                    <div className="rf_des">
                      <TextArea
                        forLabel={translate('reportRegistration.content')}
                        textTitle={translate('reportRegistration.content')}
                        hidden
                        name="content"
                        value={dataPopup ? dataPopup.content : null}
                      />
                    </div>
                    <div className="rf_file">
                      <label htmlFor={translate('reportReply.contentLable3')}>{translate('reportReply.contentLable3')}</label>
                      <div className="rff_file_name">
                        {dataPopup &&
                          dataPopup.fileList.map((item, index) => {
                            return (
                              <a
                                key={index}
                                className="file_name"
                                href={downloadFileUpload({
                                  id: item.id,
                                  fileName: item.originalFileName
                                })}
                              // rel="noopener noreferrer"
                              // target="_blank"
                              >
                                {item.originalFileName}
                              </a>
                            );
                          })}
                      </div>
                    </div>
                    <div className="wrap_form_1">
                      <div className="rps_title">
                        {translate('reportRegistration.lable_1.title')}
                      </div>
                      <Controller
                        as={
                          <Radio.Group className="rps_gr" disabled>
                            <Radio value="1">
                              {translate('reportRegistration.lable_1.value_1')}
                            </Radio>
                            <Radio value="2">
                              {translate('reportRegistration.lable_1.value_2')}
                            </Radio>
                            <Radio value="3">
                              {translate('reportRegistration.lable_1.value_3')}
                            </Radio>
                            <Radio value="4">
                              {translate('reportRegistration.lable_1.value_4')}
                            </Radio>
                            <Radio value="5">
                              {translate('reportRegistration.lable_1.value_5')}
                            </Radio>
                            <Radio value="6">
                              {translate('reportRegistration.lable_1.value_6')}
                            </Radio>
                          </Radio.Group>
                        }
                        defaultValue={
                          dataPopup ? dataPopup.surveyQuestion01 : ''
                        }
                        control={control}
                        name="Question01"
                      />
                    </div>
                    <div className="wrap_form_1 wrap_form_2">
                      <div className="rps_title">
                        {translate('reportRegistration.lable_2.title')}
                      </div>
                      <Controller
                        as={
                          <Radio.Group className="rps_gr2" disabled>
                            <Radio value="1">
                              {translate('reportRegistration.lable_2.value_1')}
                            </Radio>
                            <Radio value="2">
                              {translate('reportRegistration.lable_2.value_2')}
                            </Radio>
                            <Radio value="3">
                              {translate('reportRegistration.lable_2.value_3')}
                            </Radio>
                            <Radio value="4">
                              {translate('reportRegistration.lable_2.value_4')}
                            </Radio>
                            <Radio value="5">
                              {translate('reportRegistration.lable_2.value_5')}
                            </Radio>
                          </Radio.Group>
                        }
                        defaultValue={
                          dataPopup ? dataPopup.surveyQuestion02 : ''
                        }
                        control={control}
                        name="Question02"
                      />
                    </div>
                    <div className="wrap_form_1 wrap_form_3">
                      <div className="rps_title">
                        {translate('reportRegistration.lable_3.title')}
                      </div>
                      <Controller
                        as={
                          <Radio.Group className="rps_gr2" disabled>
                            <Radio value="1">
                              {translate('reportRegistration.lable_3.value_1')}
                            </Radio>
                            <Radio value="2">
                              {translate('reportRegistration.lable_3.value_2')}
                            </Radio>
                            <Radio value="3">
                              {translate('reportRegistration.lable_3.value_3')}
                            </Radio>
                            <Radio value="4">
                              {translate('reportRegistration.lable_3.value_4')}
                            </Radio>
                            <Radio value="5">
                              {translate('reportRegistration.lable_3.value_5')}
                            </Radio>
                            <Radio value="6">
                              {translate('reportRegistration.lable_3.value_6')}
                            </Radio>
                          </Radio.Group>
                        }
                        defaultValue={
                          dataPopup ? dataPopup.surveyQuestion03 : ''
                        }
                        control={control}
                        name="Question03"
                      />
                    </div>
                    <div className="add_content">
                      <Input
                        forLabel={translate('reportRegistration.textbox_1')}
                        classname_input={`ac_input1 ${
                          report_lang === 'kr' ? 'ac_input_kr' : 'ac_input_en'
                          }`}
                        inputTitle={translate('reportRegistration.textbox_1')}
                        name="Question04"
                        hidden
                        disable
                        defaultValue={
                          dataPopup ? dataPopup.surveyQuestion04 : null
                        }
                      />
                      <Input
                        forLabel={translate('reportRegistration.textbox_2')}
                        classname_input={`ac_input2 ${
                          report_lang === 'kr' ? 'ac_input_kr' : 'ac_input_en'
                          }`}
                        inputTitle={translate('reportRegistration.textbox_2')}
                        name="Question05"
                        hidden
                        disable
                        defaultValue={
                          dataPopup ? dataPopup.surveyQuestion05 : null
                        }
                      />
                    </div>
                    <Input
                      forLabel={translate('reportRegistration.rpName')}
                      classname_input="rf_name input_disable"
                      inputTitle={translate('reportRegistration.rpName')}
                      disable
                      hidden
                      name="name"
                      defaultValue={dataPopup ? dataPopup.name ? dataPopup.kindCustomer === 'R' ? dataPopup.name: translate('reportDetail.nameCustomer') : null : null}
                    />
                    <Input
                      forLabel={translate('reportRegistration.rpDate')}
                      classname_input="rf_name input_disable"
                      inputTitle={translate('reportRegistration.rpDate')}
                      disable
                      hidden
                      name="name"
                      defaultValue={dataPopup && dataPopup.birthday ? activeLanguage.code === 'kr' ? moment(dataPopup.birthday).format(DATE_FORMAT_KR) : moment(dataPopup.birthday).format(DATE_FORMAT_EN) : ''}
                      value={dataPopup && dataPopup.birthday ? activeLanguage.code === 'kr' ? moment(dataPopup.birthday).format(DATE_FORMAT_KR) : moment(dataPopup.birthday).format(DATE_FORMAT_EN) : ''}
                    />
                    {/* <Controller
                      as={<DatePicker />}
                      // placeholder="YYYY-MM-DD"
                      inputTitle={translate('reportRegistration.rpDate')}
                      disabled
                      classname_input="rf_datepicker inputText input_disable"
                      name="dateOfBirth"
                      checked={new Date(birthday)}
                      control={control}
                    /> */}
                    <Input
                      forLabel={translate('reportRegistration.rpEmail')}
                      disable
                      classname_input="rf_email input_disable"
                      inputTitle={translate('reportRegistration.rpEmail')}
                      hidden
                      name="email"
                      defaultValue={dataPopup ? dataPopup.email : null}
                    />
                    {code === 'kr' ? null : (
                      <div className="rf_phone input_disable">
                        <Input
                          forLabel='Country'
                          disable
                          hidden
                          inputTitle="Country"
                          classname_input="rfp_phone"
                          defaultValue={dataPopup ? dataPopup.country : null}
                        />
                      </div>
                    )}
                    {code === 'kr' ? null : (
                      <div className="rf_phone input_disable">
                        <Input
                          forLabel='Region'
                          disable
                          hidden
                          inputTitle="Region"
                          classname_input="rfp_phone"
                          defaultValue={dataPopup ? dataPopup.region : null}
                        />
                      </div>
                    )}
                    <Input
                      forLabel={translate('reportRegistration.mobilePhone')}
                      disable
                      hidden
                      inputTitle={translate('reportRegistration.mobilePhone')}
                      type="number"
                      typeDetail="phone"
                      classname_input="rfp_phone input_disable"
                      name="mobilePhone"
                      defaultValue={dataPopup ? dataPopup.mobile : null}
                    />
                    <Input
                      forLabel={translate('reportRegistration.rpPhone')}
                      disable
                      inputTitle={translate('reportRegistration.rpPhone')}
                      type="number"
                      typeDetail="phone"
                      classname_input="rfm_mobilephone input_disable"
                      hidden
                      name="telePhone"
                      defaultValue={dataPopup ? dataPopup.telephone : null}
                    />
                    <div className="name_wrap"></div>
                    <div className="email_wrap"></div>
                    <div className="email_wrap">
                      <div className="rf_phone"></div>
                    </div>
                    <div className="rf_mobilephone"></div>
                  </Modal>
                </div>
                <div className="df_width df_content df_background df_textWrap">
                  {translate('reportDetail.date')}
                </div>
                <div className="df_content df_textWrap df_bright">
                  {reformattedReportDate}
                </div>
                <div className="df_width df_content df_background df_textWrap">
                  {translate('reportDetail.status')}
                </div>
                <div className="df_content df_textWrap df_bright">
                  <div className="dft_point">
                    <span>
                      {statusCode === '1'
                        ? translate('reportDetail.status1')
                        : null}
                      {statusCode === '2'
                        ? translate('reportDetail.status2')
                        : null}
                      {statusCode === '3'
                        ? translate('reportDetail.status3')
                        : null}
                      {statusCode === '4'
                        ? translate('reportDetail.status4')
                        : null}
                      {statusCode === '5'
                        ? translate('reportDetail.status5')
                        : null}
                      {statusCode === '6'
                        ? translate('reportDetail.status6')
                        : null}
                      {statusCode === '7'
                        ? translate('reportDetail.status7')
                        : null}
                    </span>
                  </div>
                </div>
                {/* <div className="df_width df_content df_background df_textWrap">
                  {translate('reportDetail.attach')}
                </div>
                <div className="df_content df_textWrap df_bright df_overflow">
                  {fileNames
                    ? fileNames.map(item => {
                        return (
                          <a
                            key
                            className="file_name"
                            href={downloadFileUpload({
                              url: item.url,
                              fileName: item.name
                            })}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {item.name},
                          </a>
                        );
                      })
                    : '---'}
                </div> */}
                <div className="rd_comment_admin">
                  {replyList &&
                    replyList.map((item, index) => {
                      return (
                        <div className="rdca_comment_wrap" key={index}>
                          <span
                            className={
                              item.userId
                                ? 'rdcac_name'
                                : 'rdcac_name rdcac_name_customer'
                            }
                          >
                            {item.userId
                              ? translate('reportDetail.adminName')
                              : dataPopup.name && dataPopup.kindCustomer === 'A'
                                ? translate('reportDetail.nameCustomer')
                                : dataPopup.name}
                          </span>
                          <div
                            className="rdcac_comment"
                            // dangerouslySetInnerHTML={{
                            //   __html: item.content
                            // }}
                          >{item.content}</div>
                          <div className="rdcac_date">
                            {activeLanguage.code === 'kr' ? moment(item.dateIns).format(DATE_FORMAT_KR) : moment(item.dateIns).format(DATE_FORMAT_EN)}
                          </div>
                          <div className="rdcac_file">
                            {item.fileReplyList.length === 0 ? null : (
                              <div className="rdcacf_title">
                                {translate('reportDetail.attach')}
                              </div>
                            )}
                            <div className="rdcacf_filename_wrap">
                              {item.fileReplyList.map((file, index) => {
                                return (
                                  <div key={index} className="rdcacf_name">
                                    {item.userId ? null : (
                                      <img src="/Images/attached.svg" alt="" />
                                    )}
                                    <a
                                      key={true}
                                      className="file_name"
                                      href={downloadFileUpload({
                                        id: file.id,
                                        fileName: file.originalFileName
                                      })}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      {file.originalFileName}
                                    </a>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>

                {/* <div className="df_width df_content df_background df_textWrap">
                  {translate('reportDetail.treatment')}
                </div>
                <div className="df_content df_textWrap df_bright df_comment">
                  <span>
                    {replycomment ? replycomment : '---'}
                  </span>
                </div> */}
              </div>
              <div className="detail_text_below">
                <span>
                  {statusCode === '1'
                    ? translate('reportDetail.textbelow1')
                    : null}
                  {statusCode === '2'
                    ? translate('reportDetail.textbelow2')
                    : null}
                  {statusCode === '3' ||
                    statusCode === '4' ||
                    statusCode === '5' ||
                    statusCode === '6'
                    ? translate('reportDetail.textbelow3')
                    : null}
                  {statusCode === '7'
                    ? translate('reportDetail.textbelow4')
                    : null}
                </span>
              </div>
              {statusCode === '7' || replyList.length === 0 ? null : (
                <div className="detail_gr_button">
                  <button
                    className={`dgr_btn ${btnActive}`}
                    onClick={() => {
                      setVisible(true);
                      setBtnActive('btn-active');
                      setTimeout(() => {
                        let uploadBtn = document.querySelectorAll('input[type=file]');
                        uploadBtn[0].setAttribute('title', translate('createReport.uploadFile'));
                      }, 1000);
                    }}
                    onFocus={() => {
                      setBtnActive('');
                    }}
                    title={translate('TooltipReport.btnReply')}
                  >
                    <span>{translate('reportDetail.btnReply')}</span>
                    <Modal
                      className="report_reply"
                      maskClosable={false}
                      visible={visible}
                      footer={null}
                      centered
                      width="996px"
                      onCancel={e => {
                        e.stopPropagation();
                        setVisible(false);
                      }}
                      closeIcon={<img src="/Images/icclose36px.svg" alt={translate('webAccessibility.closeButton')} />}
                    >
                      <div className="report_reply">
                        <div className="rr_content_wrap">
                          <div className="wrap">
                            <div className="rrcw_content">
                              <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="content_report">
                                  <div className="rrcwc_form">
                                    <div className="rf_des">
                                      <TextArea
                                        forLabel={translate('reportReply.contentLable2')}
                                        textTitle={translate('reportReply.contentLable2')}
                                        hidden={false}
                                        name="content"
                                        errors={
                                          errors.content &&
                                          errors.content.message
                                        }
                                        register={register({})}
                                      />
                                    </div>
                                    <Controller
                                      className="rf_upload"
                                      as={<Upload />}
                                      sitesUrl={pathname}
                                      tooltipTitle={translate('TooltipReport.btnUploadDetail')}
                                      onError={(e, res) => onErrorFile(res)}
                                      onUploadDone={e => onUploadDone(e)}
                                      onRemove={e => onRemove(e)}
                                      control={control}
                                      hidden={false}
                                      classname_input="rf_upload"
                                      name="listFiles"
                                      setValue={setValue}
                                      removeItems={isRemoveItems}
                                    />

                                  </div>
                                </div>
                              </form>

                              <div className="confirm_btn">
                                <button
                                  title={translate('TooltipReport.btnSubmit')}
                                  onClick={handleSubmit(onSubmit)}
                                  className={loading ? 'disable_reply' : ''}
                                >
                                  {translate('reportReply.btnSubmit')}
                                </button>
                                <button
                                  title={translate('TooltipReport.btnCancel')}
                                  onClick={e => {
                                    e.stopPropagation();
                                    setVisible(false);
                                  }}
                                >
                                  {translate('reportReply.btnCancel')}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Flex className="btn_totop">
      <a href='javascript: void(0)' tabIndex="0" id='backtotopreportdetail' onClick={scrollTop} onKeyPress={handleKeyPress}>
        <img
          src="/Images/icarrowupward48px.svg"
          alt={translate('TooltipHome.btnToTop')}
          title={translate('TooltipHome.btnToTop')}
        />
        </a>
      </Flex>
    </div>
  );
};
export default withLocalize(ReportDetail);
